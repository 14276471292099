<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Diamond is one of the many allotropes of carbon. At high temperatures, diamond can be
        oxidized by oxygen in the atmosphere. Write the balanced chemical equation for this
        combustion reaction.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionA"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question230',
  components: {
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        reactionA: null,
      },
    };
  },
};
</script>
